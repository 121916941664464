import { Display, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import React, { CSSProperties } from 'react';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';
import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import './GameDetails.css';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import addPhoneIcon from './assets/addPhoneSmall.png';
import { Textfit } from 'react-textfit';

export const GameDetails = ({ highlighted, show }: { highlighted: any; show: boolean }) => {
  const bg = highlighted.bg;
  const selected = true;
  return (
    <>
      <div
        style={
          {
            // backgroundImage: `url(${bg})`,
          }
        }
        key={bg}
        className={`game_details-bg ${show ? '' : 'game_details-root_hide'} ${
          selected ? 'game_details-bg-selected' : ''
        }`}
      />
      <div className={`game_details-bg-gradient ${selected ? 'game_details-bg-gradient-selected' : ''}`} key={1} />
    </>
  );
};

export function GameDetailsSelected({
  game,
  yard,
  display,
}: {
  game: any;
  yard: YardWithGame | Yard;
  display: Display;
}) {
  return (
    <div className={`game_details-root`}>
      <div className={'game_details_selected-root'}>
        <div className={'game_details-selected-root_radial'} />
        <div className={'game_details-game-title'} />
        <div className={'game_details-title-container'}>
          <div className={'game_details-title'}>To play, scan the QR code. Your phone will become the controller!</div>
          <div className={'game_details-subtitle'}>
            Or open <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>ttr.mgy.gg</span> and enter room
            code:
            <span style={{ fontWeight: 'bold' }}>{' ' + display.code}</span>
          </div>
        </div>
        <div className={'game_details-body'}>
          <QrWithIcon display={display} title={''} />
          <div className={'game_details-block-with-icon'}>
            <div className={'game_details-block game_details_selected-yard_container'}>
              <div className={'game_details_selected-yard_body'}>
                {yard.controllers.length === 0 && <div className={'game_details-add_phone'} />}
                {/*<div className={'game_details_selected-body_title'}>*/}
                {/*  Playing {game.name}*/}
                {/*  <div className={'game_details-people'} />*/}
                {/*  {getGamePlayersString(game)}*/}
                {/*  <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', color: '#fa5d5d' }}>*/}
                {/*    {game.minPlayers !== undefined &&*/}
                {/*    yard.controllers.length > 0 &&*/}
                {/*    yard.controllers.length < game.minPlayers*/}
                {/*      ? `Requires ${game.minPlayers - yard.controllers.length} more players`*/}
                {/*      : ''}*/}
                {/*  </div>*/}
                {/*</div>*/}
                {yard.controllers.map((controller) => {
                  return (
                    <PlayerPart
                      horizontalName={false}
                      key={controller.profile.id}
                      controller={controller}
                      yard={yard}
                      size={'md'}
                    />
                  );
                })}
                {yard.controllers.length > 0 && (
                  <PlayerPart
                    horizontalName={false}
                    key={-999}
                    controller={{ profile: { name: '', avatarUrl: addPhoneIcon, id: '-999' }, isOnline: true }}
                    yard={yard}
                    size={'md'}
                  />
                )}
              </div>
            </div>
            <div className={'game_details-icon_with_text'}>
              <div className={'game_details-profile'} />
              Connected players
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const QrWithPlaceholder = ({ display, title }: { display: Display; title: React.ReactNode }) => {
  return (
    <div className={'game_details_selected-qr-with-placehoder-root'}>
      <div className={'game_details_selected-qr-with-placehoder-qr game_details_selected-fake_qr'} />
      <div
        className={'game_details_selected-qr-with-placehoder-qr'}
        style={{
          backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
            getControllerUrlConnectDisplay(display.id)
          )})`,
        }}
      >
        <div className={'game_details_selected-qr-text'}>{title}</div>
      </div>
    </div>
  );
};

export function GameDetailsText({ game, style }: { game: any; style?: CSSProperties }) {
  return (
    <div className={'game_details_text'} style={style}>
      <div style={{ fontSize: 70, fontWeight: 'bold' }}>{game.name}</div>
      <div style={{ display: 'flex' }}>
        {game.tags.split(', ').map((t, i) => (
          <div className={'game_details-tag'} key={t} style={{ marginLeft: i === 0 ? 0 : 5 }}>
            {t}
          </div>
        ))}
      </div>
      <div style={{ marginTop: 32, fontSize: '32px' }}>{game.description}</div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 32, fontSize: 32 }}>
        <div className={'game_details-sand_clock'} />
        {game.duration}
        <div className={'game_details-people'} />
        {game.players}
      </div>
    </div>
  );
}

export const QrWithIcon = ({ display, title }: { display: Display; title: React.ReactNode }) => {
  return (
    <div className={`game_details-block-with-icon`}>
      <div className={'game_details-block'}>
        <QrWithPlaceholder display={display} title={title} />
      </div>
      <div className={'game_details-icon_with_text'}>
        <div className={'game_details-phone'} />
        Scan with your camera
      </div>
    </div>
  );
};

export const PlayerPart = ({
  controller,
  yard,
  size,
  horizontalName,
}: {
  controller: BaseController;
  yard: YardWithGame | Yard;
  size: 'sm' | 'md';
  horizontalName: boolean;
}) => {
  const isHost = (yard.host ?? yard.controllers[yard.controllers.length - 1])?.profile.id === controller.profile.id;
  const speakerData = yard.voiceChatState?.speakers.find((y) => y.id === controller.profile.id);
  return (
    <div className={!horizontalName ? 'game_details-avatar-root' : ''}>
      {!horizontalName && isHost && <div className={'game_details-host'}>👑</div>}
      <div
        className={`game_details-avatar-container game_details-avatar-container-${size} ${
          horizontalName ? '' : 'game_details-avatar-container-vertical'
        }`}
        key={controller.profile.id}
      >
        <div
          className={`game_details-avatar game_details-avatar-${size}  ${
            speakerData === undefined
              ? ''
              : speakerData.muted
              ? 'game_details-avatar-container-speaker-muted'
              : 'game_details-avatar-container-speaker'
          }`}
          style={{ backgroundImage: `url(${controller.profile.avatarUrl})` }}
        />
        {!horizontalName ? (
          <Textfit style={{ height: 35, width: '100%', textAlign: 'center' }}>{controller.profile.name}</Textfit>
        ) : (
          `${controller.profile.name} ${isHost ? '👑' : ''}`
        )}
      </div>
    </div>
  );
};
