import type { BoardProps } from 'boardgame.io/react';
import { NAMES_TO_COLORS } from './Game';
import { PlayerID } from 'boardgame.io';

export enum UserType {
  FirstTimeUser = 'firstTimeUser',
  PayingUser = 'payingUser',
  SecondTimeUser = 'secondTimeUser',
  Bot = 'bot',
}

export enum GamePhase {
  Intro = 'intro',
  Setup = 'setup',
  Tutorial = 'tutorial',
  MissionSelection = 'missionSelection',
  GameTurns = 'gameTurns',
  Scoring = 'scoring',
  Winner = 'winner',
  PlayAgain = 'playAgain',
}

export enum GameMode {
  UsaClassic = 'usaClassic',
  ExpansionClassic = '1910Classic',
  BigCities = '1910BigCities',
  Demo = 'demo',
  Mega = 'mega',
}

export interface TrainCard {
  Color: TrainColor;
}

export interface MissionCard {
  From: City;
  To: City;
  Value: number;
}

export type PlayerData = {
  trainCards: Array<TrainCard>;
  missionCards: Array<PlayerMission>;
  score: number;
  longestRoad: PlayerLongestRoad;
  isGlobeTrotter: boolean;
  trainCars: number;
  turnState: TurnState;
};

export type PlayerLongestRoad = {
  longestPath: Array<Route>;
  length: number;
  isLongest: boolean;
};

export type TurnState = {
  missionCardsDiscovered: Array<MissionCard>;
  TrainCardsPicked: Array<TrainCard>;
  availableRoutesToBuildByCardColor: Record<string, Array<Route>>;
  usedTrainCards: Array<TrainCard>;
};

export type BoardData = {
  trainCardsDeck: Array<TrainCard>;
  trainCardsTable: Array<TrainCard>;
  usedTrainCards: Array<TrainCard>;
  usedMissionCards: Array<MissionCard>;
  missionsCardsDeck: Array<MissionCard>;
  lastTurnCounter: number;
  routes: Array<Route>;
  winnerID: number;
  cardsDiscardedByJokersLastTurn: Array<Array<TrainCard>>;
};

export type RulesData = {
  numberOfTrainCardsEachPlayerStartsWith: number;
  numberOfTrainCarsEachPlayerStartsWith: number;
  numberOfTrainCardsOpenOnTheTable: number;
  minAmountOfMissionCardsToSelectDuringGame: number;
  minAmountOfMissionCardsToSelectDuringMissionSelectPhase: number;
  amountOfMissionCardsToDealDuringGame: number;
  amountOfMissionCardsToDealDuringMissionSelectPhase: number;
  rewardForEstablishingRouteByRouteLength: Record<number, number>;
};

export type PlayerMission = {
  missionCard: MissionCard;
  status: boolean;
  path: Array<Route>;
};

export type Route = {
  cityA: City;
  cityB: City;
  routeColor: TrainColor;
  numberOfTrains: number;
  ownerID?: number;
  routeID?: number;
};

export enum TrainColor {
  Red = 'Red',
  Orange = 'Orange',
  Yellow = 'Yellow',
  Green = 'Green',
  Blue = 'Blue',
  Pink = 'Pink',
  Black = 'Black',
  White = 'White',
  Multicolored = 'Multicolored',
  Any = 'Any',
}

export type City =
  | 'Atlanta'
  | 'Boston'
  | 'Calgary'
  | 'Charleston'
  | 'Chicago'
  | 'Dallas'
  | 'Denver'
  | 'Duluth'
  | 'El Paso'
  | 'Helena'
  | 'Houston'
  | 'Kansas City'
  | 'Las Vegas'
  | 'Little Rock'
  | 'Los Angeles'
  | 'Miami'
  | 'Montreal'
  | 'Nashville'
  | 'New Orleans'
  | 'New York'
  | 'Oklahoma City'
  | 'Omaha'
  | 'Phoenix'
  | 'Pittsburgh'
  | 'Portland'
  | 'Raleigh'
  | 'Saint Louis'
  | 'Salt Lake City'
  | 'San Francisco'
  | 'Santa Fe'
  | 'Sault St Marie'
  | 'Seattle'
  | 'Saint Louis'
  | 'Toronto'
  | 'Vancouver'
  | 'Washington'
  | 'Winnipeg';

export interface PlayerInfo {
  name: string;
  avatarUrl: string;
  color: string;
  colorName: keyof typeof NAMES_TO_COLORS;
  isBot?: boolean;
  isReady: boolean;
  userType: UserType;
}

export interface GameState {
  players: Array<PlayerInfo>;
  colors: Array<string>;
  playersData: Array<PlayerData>;
  boardData: BoardData;
  gameMode: GameMode;
  seniorUserType: UserType;
  rulesData: RulesData;
  turnEnded: { playerID: PlayerID; turn: number } | null;
  phaseEnded: { phase: string } | null;
  gameEnded: boolean;
  turnOrder: string[];
  tutorialStep: number;
}

export type TTRBoardProps = BoardProps<GameState>;
