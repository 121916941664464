import { MissionCard, Route, TrainCard, TrainColor } from './Types';

const trainStandardCardsColors: TrainCard[] = [
  {
    Color: TrainColor.Pink,
  },
  {
    Color: TrainColor.Red,
  },
  {
    Color: TrainColor.Orange,
  },
  {
    Color: TrainColor.Yellow,
  },
  {
    Color: TrainColor.Blue,
  },
  {
    Color: TrainColor.Green,
  },
  {
    Color: TrainColor.Black,
  },
  {
    Color: TrainColor.White,
  },
];

const trainSpecialCardsColors: TrainCard[] = [
  {
    Color: TrainColor.Multicolored,
  },
];

const missionCards: MissionCard[] = [
  {
    From: 'Boston',
    To: 'Miami',
    Value: 12,
  },
  {
    From: 'Calgary',
    To: 'Phoenix',
    Value: 13,
  },
  {
    From: 'Calgary',
    To: 'Salt Lake City',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'New Orleans',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'Santa Fe',
    Value: 9,
  },
  {
    From: 'Dallas',
    To: 'New York',
    Value: 11,
  },
  {
    From: 'Denver',
    To: 'El Paso',
    Value: 4,
  },
  {
    From: 'Denver',
    To: 'Pittsburgh',
    Value: 11,
  },
  {
    From: 'Duluth',
    To: 'El Paso',
    Value: 10,
  },
  {
    From: 'Duluth',
    To: 'Houston',
    Value: 8,
  },
  {
    From: 'Helena',
    To: 'Los Angeles',
    Value: 8,
  },
  {
    From: 'Kansas City',
    To: 'Houston',
    Value: 5,
  },
  {
    From: 'Los Angeles',
    To: 'Chicago',
    Value: 16,
  },
  {
    From: 'Los Angeles',
    To: 'Miami',
    Value: 20,
  },
  {
    From: 'Los Angeles',
    To: 'New York',
    Value: 21,
  },
  {
    From: 'Montreal',
    To: 'Atlanta',
    Value: 9,
  },
  {
    From: 'Montreal',
    To: 'New Orleans',
    Value: 13,
  },
  {
    From: 'New York',
    To: 'Atlanta',
    Value: 6,
  },
  {
    From: 'Portland',
    To: 'Nashville',
    Value: 17,
  },
  {
    From: 'Portland',
    To: 'Phoenix',
    Value: 11,
  },
  {
    From: 'San Francisco',
    To: 'Atlanta',
    Value: 17,
  },
  {
    From: 'Sault St Marie',
    To: 'Nashville',
    Value: 8,
  },
  {
    From: 'Sault St Marie',
    To: 'Oklahoma City',
    Value: 9,
  },
  {
    From: 'Seattle',
    To: 'Los Angeles',
    Value: 9,
  },
  {
    From: 'Seattle',
    To: 'New York',
    Value: 22,
  },
  {
    From: 'Toronto',
    To: 'Miami',
    Value: 10,
  },
  {
    From: 'Vancouver',
    To: 'Santa Fe',
    Value: 13,
  },
  {
    From: 'Winnipeg',
    To: 'Houston',
    Value: 12,
  },
  {
    From: 'Winnipeg',
    To: 'Little Rock',
    Value: 11,
  },
  {
    From: 'Vancouver',
    To: 'Montreal',
    Value: 20,
  },
];

const missionCardsExpansionClassic: MissionCard[] = [
  {
    From: 'Boston',
    To: 'Washington',
    Value: 4,
  },
  {
    From: 'Calgary',
    To: 'Nashville',
    Value: 14,
  },
  {
    From: 'Calgary',
    To: 'Phoenix',
    Value: 13,
  },
  {
    From: 'Calgary',
    To: 'Salt Lake City',
    Value: 7,
  },
  {
    From: 'Denver',
    To: 'El Paso',
    Value: 4,
  },
  {
    From: 'Denver',
    To: 'Pittsburgh',
    Value: 11,
  },
  {
    From: 'Denver',
    To: 'Saint Louis',
    Value: 6,
  },
  {
    From: 'Duluth',
    To: 'El Paso',
    Value: 10,
  },
  {
    From: 'Kansas City',
    To: 'Boston',
    Value: 11,
  },
  {
    From: 'Montreal',
    To: 'Atlanta',
    Value: 9,
  },
  {
    From: 'Montreal',
    To: 'New Orleans',
    Value: 13,
  },
  {
    From: 'Montreal',
    To: 'Raleigh',
    Value: 7,
  },
  {
    From: 'Omaha',
    To: 'New Orleans',
    Value: 8,
  },
  {
    From: 'Phoenix',
    To: 'Boston',
    Value: 19,
  },
  {
    From: 'Pittsburgh',
    To: 'New Orleans',
    Value: 8,
  },
  {
    From: 'Portland',
    To: 'Nashville',
    Value: 17,
  },
  {
    From: 'Portland',
    To: 'Phoenix',
    Value: 11,
  },
  {
    From: 'Portland',
    To: 'Pittsburgh',
    Value: 19,
  },
  {
    From: 'Salt Lake City',
    To: 'Kansas City',
    Value: 7,
  },
  {
    From: 'San Francisco',
    To: 'Atlanta',
    Value: 17,
  },
  {
    From: 'San Francisco',
    To: 'Sault St Marie',
    Value: 17,
  },
  {
    From: 'San Francisco',
    To: 'Washington',
    Value: 21,
  },
  {
    From: 'Sault St Marie',
    To: 'Nashville',
    Value: 8,
  },
  {
    From: 'Sault St Marie',
    To: 'Oklahoma City',
    Value: 8,
  },
  {
    From: 'Toronto',
    To: 'Charleston',
    Value: 6,
  },
  {
    From: 'Vancouver',
    To: 'Denver',
    Value: 11,
  },
  {
    From: 'Vancouver',
    To: 'Duluth',
    Value: 13,
  },
  {
    From: 'Vancouver',
    To: 'Montreal',
    Value: 20,
  },
  {
    From: 'Vancouver',
    To: 'Portland',
    Value: 2,
  },
  {
    From: 'Vancouver',
    To: 'Santa Fe',
    Value: 13,
  },
  {
    From: 'Washington',
    To: 'Atlanta',
    Value: 4,
  },
  {
    From: 'Winnipeg',
    To: 'Little Rock',
    Value: 11,
  },
  {
    From: 'Winnipeg',
    To: 'Omaha',
    Value: 6,
  },
  {
    From: 'Winnipeg',
    To: 'Santa Fe',
    Value: 10,
  },
];

const missionCardsMega: MissionCard[] = [
  {
    From: 'Boston',
    To: 'Miami',
    Value: 12,
  },
  {
    From: 'Boston',
    To: 'Washington',
    Value: 4,
  },
  {
    From: 'Calgary',
    To: 'Nashville',
    Value: 14,
  },
  {
    From: 'Calgary',
    To: 'Phoenix',
    Value: 13,
  },
  {
    From: 'Calgary',
    To: 'Salt Lake City',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'Atlanta',
    Value: 5,
  },
  {
    From: 'Chicago',
    To: 'Boston',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'New Orleans',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'New York',
    Value: 5,
  },
  {
    From: 'Chicago',
    To: 'Santa Fe',
    Value: 9,
  },
  {
    From: 'Dallas',
    To: 'New York',
    Value: 11,
  },
  {
    From: 'Denver',
    To: 'El Paso',
    Value: 4,
  },
  {
    From: 'Denver',
    To: 'Pittsburgh',
    Value: 11,
  },
  {
    From: 'Denver',
    To: 'Saint Louis',
    Value: 6,
  },
  {
    From: 'Duluth',
    To: 'Dallas',
    Value: 7,
  },
  {
    From: 'Duluth',
    To: 'El Paso',
    Value: 10,
  },
  {
    From: 'Duluth',
    To: 'Houston',
    Value: 8,
  },
  {
    From: 'Helena',
    To: 'Los Angeles',
    Value: 8,
  },
  {
    From: 'Houston',
    To: 'Washington',
    Value: 10,
  },
  {
    From: 'Kansas City',
    To: 'Boston',
    Value: 11,
  },
  {
    From: 'Kansas City',
    To: 'Houston',
    Value: 5,
  },
  {
    From: 'Las Vegas',
    To: 'Miami',
    Value: 21,
  },
  {
    From: 'Las Vegas',
    To: 'New York',
    Value: 19,
  },
  {
    From: 'Los Angeles',
    To: 'Atlanta',
    Value: 15,
  },
  {
    From: 'Los Angeles',
    To: 'Calgary',
    Value: 12,
  },
  {
    From: 'Los Angeles',
    To: 'Chicago',
    Value: 16,
  },
  {
    From: 'Los Angeles',
    To: 'Miami',
    Value: 19,
  },
  {
    From: 'Los Angeles',
    To: 'New York',
    Value: 20,
  },
  {
    From: 'Los Angeles',
    To: 'Oklahoma City',
    Value: 9,
  },
  {
    From: 'Montreal',
    To: 'Atlanta',
    Value: 9,
  },
  {
    From: 'Montreal',
    To: 'Chicago',
    Value: 7,
  },
  {
    From: 'Montreal',
    To: 'Dallas',
    Value: 13,
  },
  {
    From: 'Montreal',
    To: 'New Orleans',
    Value: 13,
  },
  {
    From: 'Montreal',
    To: 'Raleigh',
    Value: 7,
  },
  {
    From: 'Nashville',
    To: 'New York',
    Value: 6,
  },
  {
    From: 'New York',
    To: 'Atlanta',
    Value: 6,
  },
  {
    From: 'New York',
    To: 'Miami',
    Value: 10,
  },
  {
    From: 'Omaha',
    To: 'New Orleans',
    Value: 8,
  },
  {
    From: 'Phoenix',
    To: 'Boston',
    Value: 19,
  },
  {
    From: 'Pittsburgh',
    To: 'New Orleans',
    Value: 8,
  },
  {
    From: 'Portland',
    To: 'Houston',
    Value: 16,
  },
  {
    From: 'Portland',
    To: 'Nashville',
    Value: 17,
  },
  {
    From: 'Portland',
    To: 'Phoenix',
    Value: 11,
  },
  {
    From: 'Portland',
    To: 'Pittsburgh',
    Value: 19,
  },
  {
    From: 'Salt Lake City',
    To: 'Chicago',
    Value: 11,
  },
  {
    From: 'Salt Lake City',
    To: 'Kansas City',
    Value: 7,
  },
  {
    From: 'San Francisco',
    To: 'Atlanta',
    Value: 17,
  },
  {
    From: 'San Francisco',
    To: 'Sault St Marie',
    Value: 17,
  },
  {
    From: 'San Francisco',
    To: 'Washington',
    Value: 21,
  },
  {
    From: 'Sault St Marie',
    To: 'Miami',
    Value: 12,
  },
  {
    From: 'Sault St Marie',
    To: 'Nashville',
    Value: 8,
  },
  {
    From: 'Sault St Marie',
    To: 'Oklahoma City',
    Value: 8,
  },
  {
    From: 'Seattle',
    To: 'Las Vegas',
    Value: 10,
  },
  {
    From: 'Seattle',
    To: 'Los Angeles',
    Value: 9,
  },
  {
    From: 'Seattle',
    To: 'New York',
    Value: 20,
  },
  {
    From: 'Seattle',
    To: 'Oklahoma City',
    Value: 14,
  },
  {
    From: 'Saint Louis',
    To: 'Miami',
    Value: 8,
  },
  {
    From: 'Toronto',
    To: 'Charleston',
    Value: 6,
  },
  {
    From: 'Toronto',
    To: 'Miami',
    Value: 10,
  },
  {
    From: 'Vancouver',
    To: 'Denver',
    Value: 11,
  },
  {
    From: 'Vancouver',
    To: 'Duluth',
    Value: 13,
  },
  {
    From: 'Vancouver',
    To: 'Montreal',
    Value: 20,
  },
  {
    From: 'Vancouver',
    To: 'Portland',
    Value: 2,
  },
  {
    From: 'Vancouver',
    To: 'Santa Fe',
    Value: 13,
  },
  {
    From: 'Washington',
    To: 'Atlanta',
    Value: 4,
  },
  {
    From: 'Winnipeg',
    To: 'Houston',
    Value: 12,
  },
  {
    From: 'Winnipeg',
    To: 'Little Rock',
    Value: 11,
  },
  {
    From: 'Winnipeg',
    To: 'Omaha',
    Value: 6,
  },
  {
    From: 'Winnipeg',
    To: 'Santa Fe',
    Value: 10,
  },
];

const missionCardsBigCities: MissionCard[] = [
  {
    From: 'Boston',
    To: 'Miami',
    Value: 12,
  },
  {
    From: 'Chicago',
    To: 'Atlanta',
    Value: 5,
  },
  {
    From: 'Chicago',
    To: 'Boston',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'New Orleans',
    Value: 7,
  },
  {
    From: 'Chicago',
    To: 'New York',
    Value: 5,
  },
  {
    From: 'Chicago',
    To: 'Santa Fe',
    Value: 9,
  },
  {
    From: 'Dallas',
    To: 'New York',
    Value: 11,
  },
  {
    From: 'Duluth',
    To: 'Dallas',
    Value: 7,
  },
  {
    From: 'Duluth',
    To: 'Houston',
    Value: 8,
  },
  {
    From: 'Helena',
    To: 'Los Angeles',
    Value: 8,
  },
  {
    From: 'Houston',
    To: 'Washington',
    Value: 10,
  },
  {
    From: 'Kansas City',
    To: 'Houston',
    Value: 5,
  },
  {
    From: 'Las Vegas',
    To: 'Miami',
    Value: 21,
  },
  {
    From: 'Las Vegas',
    To: 'New York',
    Value: 19,
  },
  {
    From: 'Los Angeles',
    To: 'Atlanta',
    Value: 15,
  },
  {
    From: 'Los Angeles',
    To: 'Calgary',
    Value: 12,
  },
  {
    From: 'Los Angeles',
    To: 'Chicago',
    Value: 16,
  },
  {
    From: 'Los Angeles',
    To: 'Miami',
    Value: 19,
  },
  {
    From: 'Los Angeles',
    To: 'New York',
    Value: 20,
  },
  {
    From: 'Los Angeles',
    To: 'Oklahoma City',
    Value: 9,
  },
  {
    From: 'Montreal',
    To: 'Chicago',
    Value: 7,
  },
  {
    From: 'Montreal',
    To: 'Dallas',
    Value: 13,
  },
  {
    From: 'Nashville',
    To: 'New York',
    Value: 6,
  },
  {
    From: 'New York',
    To: 'Atlanta',
    Value: 6,
  },
  {
    From: 'New York',
    To: 'Miami',
    Value: 10,
  },
  {
    From: 'Portland',
    To: 'Houston',
    Value: 16,
  },
  {
    From: 'Salt Lake City',
    To: 'Chicago',
    Value: 11,
  },
  {
    From: 'Sault St Marie',
    To: 'Miami',
    Value: 12,
  },
  {
    From: 'Seattle',
    To: 'Las Vegas',
    Value: 10,
  },
  {
    From: 'Seattle',
    To: 'Los Angeles',
    Value: 9,
  },
  {
    From: 'Seattle',
    To: 'New York',
    Value: 20,
  },
  {
    From: 'Seattle',
    To: 'Oklahoma City',
    Value: 14,
  },
  {
    From: 'Saint Louis',
    To: 'Miami',
    Value: 8,
  },
  {
    From: 'Toronto',
    To: 'Miami',
    Value: 10,
  },
  {
    From: 'Winnipeg',
    To: 'Houston',
    Value: 12,
  },
];

const routes: Route[] = [
  { cityA: 'Miami', cityB: 'Atlanta', routeColor: TrainColor.Blue, numberOfTrains: 5 },
  { cityA: 'Nashville', cityB: 'Atlanta', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'New Orleans', cityB: 'Atlanta', routeColor: TrainColor.Yellow, numberOfTrains: 4 },
  { cityA: 'New Orleans', cityB: 'Atlanta', routeColor: TrainColor.Orange, numberOfTrains: 4 },
  { cityA: 'Raleigh', cityB: 'Atlanta', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Raleigh', cityB: 'Atlanta', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Montreal', cityB: 'Boston', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Montreal', cityB: 'Boston', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Seattle', cityB: 'Calgary', routeColor: TrainColor.Any, numberOfTrains: 4 },
  { cityA: 'Vancouver', cityB: 'Calgary', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Atlanta', cityB: 'Charleston', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Raleigh', cityB: 'Charleston', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Duluth', cityB: 'Chicago', routeColor: TrainColor.Red, numberOfTrains: 3 },
  { cityA: 'Omaha', cityB: 'Chicago', routeColor: TrainColor.Blue, numberOfTrains: 4 },
  { cityA: 'Saint Louis', cityB: 'Chicago', routeColor: TrainColor.Green, numberOfTrains: 2 },
  { cityA: 'Saint Louis', cityB: 'Chicago', routeColor: TrainColor.White, numberOfTrains: 2 },
  { cityA: 'El Paso', cityB: 'Dallas', routeColor: TrainColor.Red, numberOfTrains: 4 },
  { cityA: 'Houston', cityB: 'Dallas', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Helena', cityB: 'Denver', routeColor: TrainColor.Green, numberOfTrains: 4 },
  { cityA: 'Omaha', cityB: 'Denver', routeColor: TrainColor.Pink, numberOfTrains: 4 },
  { cityA: 'Phoenix', cityB: 'Denver', routeColor: TrainColor.White, numberOfTrains: 5 },
  { cityA: 'Salt Lake City', cityB: 'Denver', routeColor: TrainColor.Yellow, numberOfTrains: 3 },
  { cityA: 'Salt Lake City', cityB: 'Denver', routeColor: TrainColor.Red, numberOfTrains: 3 },
  { cityA: 'Helena', cityB: 'Duluth', routeColor: TrainColor.Orange, numberOfTrains: 6 },
  { cityA: 'Winnipeg', cityB: 'Duluth', routeColor: TrainColor.Black, numberOfTrains: 4 },
  { cityA: 'Los Angeles', cityB: 'El Paso', routeColor: TrainColor.Black, numberOfTrains: 6 },
  { cityA: 'Phoenix', cityB: 'El Paso', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Santa Fe', cityB: 'El Paso', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Calgary', cityB: 'Helena', routeColor: TrainColor.Any, numberOfTrains: 4 },
  { cityA: 'Salt Lake City', cityB: 'Helena', routeColor: TrainColor.Pink, numberOfTrains: 3 },
  { cityA: 'Seattle', cityB: 'Helena', routeColor: TrainColor.Yellow, numberOfTrains: 6 },
  { cityA: 'Dallas', cityB: 'Houston', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'El Paso', cityB: 'Houston', routeColor: TrainColor.Green, numberOfTrains: 6 },
  { cityA: 'Denver', cityB: 'Kansas City', routeColor: TrainColor.Orange, numberOfTrains: 4 },
  { cityA: 'Denver', cityB: 'Kansas City', routeColor: TrainColor.Black, numberOfTrains: 4 },
  { cityA: 'Oklahoma City', cityB: 'Kansas City', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Oklahoma City', cityB: 'Kansas City', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Omaha', cityB: 'Kansas City', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Omaha', cityB: 'Kansas City', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Salt Lake City', cityB: 'Las Vegas', routeColor: TrainColor.Orange, numberOfTrains: 3 },
  { cityA: 'Dallas', cityB: 'Little Rock', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Nashville', cityB: 'Little Rock', routeColor: TrainColor.White, numberOfTrains: 3 },
  { cityA: 'New Orleans', cityB: 'Little Rock', routeColor: TrainColor.Green, numberOfTrains: 3 },
  { cityA: 'Oklahoma City', cityB: 'Little Rock', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Saint Louis', cityB: 'Little Rock', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Las Vegas', cityB: 'Los Angeles', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Phoenix', cityB: 'Los Angeles', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Charleston', cityB: 'Miami', routeColor: TrainColor.Pink, numberOfTrains: 4 },
  { cityA: 'Sault St Marie', cityB: 'Montreal', routeColor: TrainColor.Black, numberOfTrains: 5 },
  { cityA: 'Toronto', cityB: 'Montreal', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Pittsburgh', cityB: 'Nashville', routeColor: TrainColor.Yellow, numberOfTrains: 4 },
  { cityA: 'Saint Louis', cityB: 'Nashville', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Houston', cityB: 'New Orleans', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Miami', cityB: 'New Orleans', routeColor: TrainColor.Red, numberOfTrains: 6 },
  { cityA: 'Boston', cityB: 'New York', routeColor: TrainColor.Red, numberOfTrains: 2 },
  { cityA: 'Boston', cityB: 'New York', routeColor: TrainColor.Yellow, numberOfTrains: 2 },
  { cityA: 'Montreal', cityB: 'New York', routeColor: TrainColor.Blue, numberOfTrains: 3 },
  { cityA: 'Pittsburgh', cityB: 'New York', routeColor: TrainColor.White, numberOfTrains: 2 },
  { cityA: 'Pittsburgh', cityB: 'New York', routeColor: TrainColor.Green, numberOfTrains: 2 },
  { cityA: 'Washington', cityB: 'New York', routeColor: TrainColor.Black, numberOfTrains: 2 },
  { cityA: 'Washington', cityB: 'New York', routeColor: TrainColor.Orange, numberOfTrains: 2 },
  { cityA: 'Dallas', cityB: 'Oklahoma City', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Dallas', cityB: 'Oklahoma City', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Denver', cityB: 'Oklahoma City', routeColor: TrainColor.Red, numberOfTrains: 4 },
  { cityA: 'El Paso', cityB: 'Oklahoma City', routeColor: TrainColor.Yellow, numberOfTrains: 5 },
  { cityA: 'Santa Fe', cityB: 'Oklahoma City', routeColor: TrainColor.Blue, numberOfTrains: 3 },
  { cityA: 'Duluth', cityB: 'Omaha', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Duluth', cityB: 'Omaha', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Helena', cityB: 'Omaha', routeColor: TrainColor.Red, numberOfTrains: 5 },
  { cityA: 'Santa Fe', cityB: 'Phoenix', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Chicago', cityB: 'Pittsburgh', routeColor: TrainColor.Black, numberOfTrains: 3 },
  { cityA: 'Chicago', cityB: 'Pittsburgh', routeColor: TrainColor.Orange, numberOfTrains: 3 },
  { cityA: 'Saint Louis', cityB: 'Pittsburgh', routeColor: TrainColor.Green, numberOfTrains: 5 },
  { cityA: 'Toronto', cityB: 'Pittsburgh', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'San Francisco', cityB: 'Portland', routeColor: TrainColor.Green, numberOfTrains: 5 },
  { cityA: 'Seattle', cityB: 'Portland', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Seattle', cityB: 'Portland', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Nashville', cityB: 'Raleigh', routeColor: TrainColor.Black, numberOfTrains: 3 },
  { cityA: 'Pittsburgh', cityB: 'Raleigh', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Washington', cityB: 'Raleigh', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Washington', cityB: 'Raleigh', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Portland', cityB: 'Salt Lake City', routeColor: TrainColor.Blue, numberOfTrains: 6 },
  { cityA: 'San Francisco', cityB: 'Salt Lake City', routeColor: TrainColor.White, numberOfTrains: 5 },
  { cityA: 'San Francisco', cityB: 'Salt Lake City', routeColor: TrainColor.Orange, numberOfTrains: 5 },
  { cityA: 'Los Angeles', cityB: 'San Francisco', routeColor: TrainColor.Pink, numberOfTrains: 3 },
  { cityA: 'Los Angeles', cityB: 'San Francisco', routeColor: TrainColor.Yellow, numberOfTrains: 3 },
  { cityA: 'Portland', cityB: 'San Francisco', routeColor: TrainColor.Pink, numberOfTrains: 5 },
  { cityA: 'Denver', cityB: 'Santa Fe', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Duluth', cityB: 'Sault St Marie', routeColor: TrainColor.Any, numberOfTrains: 3 },
  { cityA: 'Winnipeg', cityB: 'Sault St Marie', routeColor: TrainColor.Any, numberOfTrains: 6 },
  { cityA: 'Vancouver', cityB: 'Seattle', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Vancouver', cityB: 'Seattle', routeColor: TrainColor.Any, numberOfTrains: 1 },
  { cityA: 'Kansas City', cityB: 'Saint Louis', routeColor: TrainColor.Pink, numberOfTrains: 2 },
  { cityA: 'Kansas City', cityB: 'Saint Louis', routeColor: TrainColor.Blue, numberOfTrains: 2 },
  { cityA: 'Chicago', cityB: 'Toronto', routeColor: TrainColor.White, numberOfTrains: 4 },
  { cityA: 'Duluth', cityB: 'Toronto', routeColor: TrainColor.Pink, numberOfTrains: 6 },
  { cityA: 'Sault St Marie', cityB: 'Toronto', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Pittsburgh', cityB: 'Washington', routeColor: TrainColor.Any, numberOfTrains: 2 },
  { cityA: 'Calgary', cityB: 'Winnipeg', routeColor: TrainColor.White, numberOfTrains: 6 },
  { cityA: 'Helena', cityB: 'Winnipeg', routeColor: TrainColor.Blue, numberOfTrains: 4 },
];

export const TRAIN_CARDS_DECK: readonly TrainCard[] = populateTrainCardsDeck(
  trainStandardCardsColors,
  12, //Game rules = 12
  trainSpecialCardsColors,
  14 //Game rules = 14
);
export const DEMO_TRAIN_CARDS_DECK: readonly TrainCard[] = [
  { Color: TrainColor.Blue },
  { Color: TrainColor.Black },
  { Color: TrainColor.Green },
  { Color: TrainColor.Orange },
  { Color: TrainColor.White },
  { Color: TrainColor.Black },
  { Color: TrainColor.White },
  { Color: TrainColor.Red },
  { Color: TrainColor.Red },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Black },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Green },
  { Color: TrainColor.White },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Green },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Black },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Black },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Red },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Red },
  { Color: TrainColor.Red },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Green },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Black },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Green },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.White },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Red },
  { Color: TrainColor.Green },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Green },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Green },
  { Color: TrainColor.Red },
  { Color: TrainColor.Green },
  { Color: TrainColor.Red },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Black },
  { Color: TrainColor.White },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Black },
  { Color: TrainColor.White },
  { Color: TrainColor.Black },
  { Color: TrainColor.White },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.White },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Black },
  { Color: TrainColor.Red },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.White },
  { Color: TrainColor.Orange },
  { Color: TrainColor.White },
  { Color: TrainColor.White },
  { Color: TrainColor.Pink },
  { Color: TrainColor.White },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Red },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Black },
  { Color: TrainColor.Red },
  { Color: TrainColor.Green },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Blue },
  { Color: TrainColor.Green },
  { Color: TrainColor.Red },
  { Color: TrainColor.Green },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Black },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Yellow },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Pink },
  { Color: TrainColor.Orange },
  { Color: TrainColor.Multicolored },
  { Color: TrainColor.Orange },
];
export const MISSION_CARDS_DECK: readonly MissionCard[] = missionCards;
export const MISSION_CARDS_DECK_EXPANSION_CLASSIC: readonly MissionCard[] = missionCardsExpansionClassic;
export const MISSION_CARDS_DECK_MEGA: readonly MissionCard[] = missionCardsMega;
export const MISSION_CARDS_DECK_BIG_CITIES: readonly MissionCard[] = missionCardsBigCities;
export const ROUTES: readonly Route[] = populateRoutesWithIDs(routes);

function populateRoutesWithIDs(routes: Route[]): Route[] {
  for (let i = 0; i < routes.length; i++) {
    routes[i].routeID = i;
  }
  return routes;
}

function populateTrainCardsDeck(
  trainStandardCards: TrainCard[],
  numberOfStandardInstances: number,
  trainSpecialCards: TrainCard[],
  numberOfSpecialInstances: number
): TrainCard[] {
  const TrainCardsDeck: TrainCard[] = [];
  for (let i = 0; i < numberOfStandardInstances; i++) {
    for (let j = 0; j < trainStandardCards.length; j++) {
      TrainCardsDeck.push(trainStandardCards[j]);
    }
  }
  for (let i = 0; i < numberOfSpecialInstances; i++) {
    for (let j = 0; j < trainSpecialCards.length; j++) {
      TrainCardsDeck.push(trainSpecialCards[j]);
    }
  }
  return TrainCardsDeck;
}
