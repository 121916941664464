import { useEffect } from 'react';

export default function useForceScale(elementSelector?: string): void {
  useEffect(() => {
    const element: HTMLElement = document.querySelector(elementSelector ?? '#root');
    const scaleEl = () => scaleElement(element);
    scaleEl();
    window.addEventListener('resize', scaleEl);
    return () => {
      window.removeEventListener('resize', scaleEl);
    };
  }, []);
}

export const scaleElement = (element: HTMLElement | null) => {
  if (element == null) {
    console.error('Could not scale, element not found');
    return;
  }
  const desiredHeight = 1080;
  const desiredWidth = 1920;

  const vw = document.documentElement.clientWidth;
  const vh = document.documentElement.clientHeight;

  const w_scale = vw / desiredWidth;
  const h_scale = vh / desiredHeight;
  const scale = Math.min(h_scale, w_scale);

  const remainingWidth = Math.max(0, vw - desiredWidth * scale);
  const remainingHeight = Math.max(0, vh - desiredHeight * scale);

  const translateX = remainingWidth / 2.0;
  const translateY = remainingHeight / 2.0;

  console.debug({
    vw: vw,
    vh: vh,
    desiredWidth: desiredWidth,
    desiredHeight: desiredHeight,
    scale: scale,
    sW: scale * desiredWidth,
    remW: remainingWidth,
    sH: scale * desiredHeight,
    remH: remainingHeight,
  });

  element.style.height = `${desiredHeight}px`;
  element.style.width = `${desiredWidth}px`;
  element.style.overflow = 'hidden';
  element.style.transformOrigin = '0 0';
  // Order of scale() and translate() matters! (e.g. switch them and you have to divide translation amts by `scale`)
  element.style.transform = `translate3d(${translateX}px,${translateY}px,0) scale(${scale})`;
};
