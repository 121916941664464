import React, { useEffect, useRef, useState } from 'react';
import { NAMES_TO_COLORS, GAME_ID } from '@magicyard/ttr-game/src/Game';
import { usePlatformDisplay } from '@magicyard/shared/platform/hooks/usePlatformDisplay';
import { objectKeys } from '@magicyard/shared/utils';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { GameDetails, GameDetailsSelected, GameDetailsText } from './GameDetails';
import { GAME } from '@magicyard/shared/games';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import { CombinedYard, Display } from '@magicyard/shared/platform/lib/api';
import { Communication } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import './App.css';

interface UnityInstance {
  SetFullScreen: (fullscreen: 1 | 0) => void;
  SendMessage: (objectName: string, methodName: string, value: string) => void;
  Quit: () => Promise<void>;
}

const useUnityInstance = () => {
  const canvas = useRef<HTMLCanvasElement>(document.querySelector('#unity-canvas'));
  const [unityInstance, setUnityInstance] = useState<UnityInstance | null>(null);
  useForceScale('#unity-canvas');
  useForceScale('#root');

  useEffect(() => {
    console.log('Will load Unity');
    (window as any)
      .createUnityInstance(canvas.current, {
        dataUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.data.unityweb`,
        frameworkUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.framework.js.unityweb`,
        codeUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.wasm.unityweb`,
        streamingAssetsUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/StreamingAssets`,
        companyName: 'MagicYard',
        productName: 'Ticket to Ride',
      })
      .then((unityInstance: UnityInstance) => {
        setUnityInstance(unityInstance);
      })
      .then(() => {
        console.log('Unity Loaded');
      });
  }, []);

  return { instance: unityInstance, canvas: canvas.current };
};

const App = () => {
  const unityInstance = useUnityInstance();
  const query = new URLSearchParams(window.location.search);
  useFullscreen();
  useForceScale();
  const [didClick, setDidClick] = useState(false);

  const platformReturn = usePlatformDisplay<
    {
      yard?: CombinedYard;
      display?: Display;
      toRender: React.ReactNode;
      communication?: Communication;
    },
    any
  >(
    {
      onLoading() {
        return { toRender: <Loading /> };
      },
      onQueue() {
        return { toRender: <div></div> };
      },
      onYardAndDisplay({ yard, display, communication }) {
        return {
          communication: communication,
          yard: yard,
          toRender: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
                width: '100vw',
              }}
            >
              {objectKeys(NAMES_TO_COLORS).map((key, i) => {
                const player = yard.controllers[i];
                return <div>{player?.profile.name}</div>;
              })}
              <GameDetails highlighted={GAME} show={true} />
              <GameDetailsSelected game={GAME} yard={yard} display={display} />
            </div>
          ),
          display: display,
        };
      },
      onLoadingGame({ yard, display, communication }) {
        return { toRender: <Loading />, display: display, yard: yard, communication: communication };
      },
      onGame({ yard, gameStartArgs, display, communication }) {
        const urlParams = new URLSearchParams(new URL((gameStartArgs as any).url).search);
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL = urlParams.get('serverURL') ?? undefined;
        const stringified = JSON.stringify({
          MatchID: matchID,
          ServerUrl: serverURL,
          Display: { id: display.id, code: display.code },
          PlayerID: '-1',
        });

        // updateDisplayIdForTestSharing(display.id);

        if (unityInstance.instance === null) {
          return {
            toRender: (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loading />
              </div>
            ),
            display: display,
            yard: yard,
            communication: communication,
          };
        }
        unityInstance.instance.SendMessage('Game Igniter', 'ReceiveGameConnectionParamsExternal', stringified);
        unityInstance.canvas.style.opacity = '1';
        unityInstance.canvas.style.zIndex = '100';
        return { toRender: <div></div>, display: display, yard: yard, communication: communication };
      },
    },
    { displayId: query.get('displayID') },
    GAME_ID
  );
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
      }}
    >
      {/*{platformReturn.display === undefined ||*/}
      {/*platformReturn.yard === undefined ||*/}
      {/*platformReturn.communication === undefined ? null : (*/}
      {/*  <QrOverlay*/}
      {/*    display={platformReturn.display}*/}
      {/*    listener={platformReturn.communication.receiveNavigationCommand}*/}
      {/*    controllers={platformReturn.yard.controllers}*/}
      {/*  />*/}
      {/*)}*/}
      {didClick ? (
        platformReturn.toRender
      ) : (
        <div
          style={{
            width: '1920px',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            zIndex: 100000,
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => setDidClick(true)}
        >
          Click to start
        </div>
      )}
    </div>
  );
};

const updateDisplayIdForTestSharing = (displayId: string) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set('displayID', displayId);
  window.history.pushState(null, '', newUrl);
};

export const Loading = () => {
  return (
    <div className={'app_loading-root'}>
      <div className={'app_loading-circle-root'}>
        <div className={'app_loading-circle-inner'} />
      </div>
    </div>
  );
};

export default App;
