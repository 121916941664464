import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const getDemoData = (): { matchId: string; displayId: string | null; serverURL: string | null } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  const serverURL = params.get('serverURL');
  if (matchId === null) {
    return null;
  }

  return { matchId: matchId, displayId: displayId, serverURL: serverURL };
};
const demoData = getDemoData();
console.log({ demoData });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
