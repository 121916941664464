import thumb_ticket_to_ride from './ticket-to-ride/thumbnail.webp';
import bg_ticket_to_ride from './ticket-to-ride/bg.webp';
export const GAME = {
  id: 'ticket-to-ride',
  name: 'Ticket To Ride',
  thumbnail: thumb_ticket_to_ride,
  bg: bg_ticket_to_ride,
  tags: 'Easy To Learn, Families, Couples With AI',
  description: 'An award winning, cross-country train adventure game.',
  players: '1-5',
  duration: '30 Min',
  launchType: 'bgio',
  isPlayable: true,
};
